<template>
  <v-row class="px-4">
    <v-col cols="6">
      <v-autocomplete v-model="params.appId" :items="dataApps" :filter="filterObject" clearable dense outlined color="blue-grey-lighten-2"
        item-title="title" item-value="id" label="Select App" @change="changeAppId">
        <template v-slot:selection="data">
          <v-list-item>
            <v-list-item-avatar>
              <img :src="data.item.image_url">
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ data.item.title }} <span style="text-transform:capitalize;">({{ data.item.type }})</span>
              </v-list-item-title>
              <v-list-item-subtitle v-html="data.item.description" />
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:item="data">
          <v-list-item-avatar>
            <img :src="data.item.image_url">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.title }} <span style="text-transform:capitalize;">({{ data.item.type }})</span>
            </v-list-item-title>
            <v-list-item-subtitle v-html="data.item.description" />
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col v-show="allDataUsers.length" cols="6">
      <v-autocomplete
        v-model="params.userId"
        :items="optionUsers"
        label="User"
        item-text="title"
        item-value="id"
        dense
        outlined
        clearable
        @change="changeUserId"
      />
    </v-col>
  </v-row>
</template>

<script>
import { sync } from 'vuex-pathify'
export default {
  name: 'FilterForm',
  data() {
    return {
      dataApps: [],
      allDataUsers: [],
    }
  },
  computed: {
    ...sync('schedule', [
      'params',
    ]),
    optionUsers() {
      const optionUsers = this.allDataUsers.map(dataUser => {
        return {
          title: `${dataUser?.fullName || ''}(${dataUser?.email || ''})`,
          id: dataUser.id,
        }
      })
      optionUsers.unshift({
        title: 'All',
        id: '',
      })
      return optionUsers
    },
  },
  async created() {
    const dataApps = await this.$store.dispatch('manageApps/getDataApps', { tokenLogin: this.getTokenLogin(), isShowPublic: true })
    this.dataApps = (this.getAppsOfUser(dataApps) || []).filter(dataApp => dataApp.dataGroup?.id)
    if (!this.isRoleUserLogin()) {
      this.allDataUsers = await this.$store.dispatch('adminUser/fetchAllDataUserByRole', { tokenLogin: this.getTokenLogin() })
    }
  },
  methods: {
    getSchedules() {
      if (this.params.offset !== 1) {
        this.params.offset = 1;
      } else {
        this.$store.dispatch('schedule/fetchDataScheduleList', this.getTokenLogin())
      }
    },
    async getDataApps() {
      const data = await this.$store.dispatch('manageApps/getDataApps', { tokenLogin: this.getTokenLogin(), isShowPublic: true })
      return data
    },
    filterObject(item, queryText) {
      if (!queryText) return true;
      const { title = '', description = '' } = item
      return (title + description).toLocaleLowerCase().includes(queryText.toLocaleLowerCase())
    },
    changeAppId() {
      if (!this.params.appId) {
        this.params.appId = ''
        this.params.userId = ''
      }
      this.getSchedules()
    },
    changeUserId() {
      if (!this.params.userId) {
        this.params.userId = ''
      }
      this.getSchedules()
    },
  },
}
</script>
