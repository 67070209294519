<template>
  <v-container
    id="admin-schedule-view"
    fluid
    tag="section"
  >
    <material-card
      color="primary"
      icon="mdi-git"
    >
      <template #title>
      {{ $t('layout.sidebar.schedule') }}
      </template>
      <filter-form />
      <table-schedule />
    </material-card>
  </v-container>
</template>

<script>
  import FilterForm from '../modules/all/schedule/components/FilterForm'
  import TableSchedule from '../modules/all/schedule/components/TableSchedule'

  export default {
    name: 'Schedule',
    components: {
      FilterForm,
      TableSchedule,
    },
    // Title page
    metaInfo: {
      titleTemplate: 'Schedule',
    },
  }
</script>
