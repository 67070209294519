<template>
  <div class="mt-5">
    <v-simple-table>
      <thead>
        <tr>
          <th
            class="primary--text font-weight-bold"
            v-text="'ID'"
          />
          <th
            class="primary--text font-weight-bold"
            v-text="'Title'"
          />
          <th
            class="primary--text font-weight-bold"
            v-text="'Progress'"
          />
          <th
            class="primary--text font-weight-bold"
            v-text="'Finish Date'"
          />
          <th
            class="primary--text font-weight-bold text-center"
            v-text="$t('user.userList.table.actions')"
          />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(data, index) in dataItems"
          :key="index+'_dataItems'"
          :style="{ background: targetScheduleId === data.id ? 'cornsilk': ''}"
        >
          <td v-text="data.id" />
          <td v-text="data.title" />
          <td v-text="data.progress" />
          <td v-text="data.finishAt" />

          <td class="text-center">
            <v-menu offset-y>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="() => clickDownloadFile(data.id)">
                  <v-list-item-title v-text="'Download'" />
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-row class="ml-2 mt-4">
      <v-col cols="4">
        <v-pagination
          v-model="params.offset"
          :length="getPageTotal(totalItem, params.limit)"
        />
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="params.limit"
          :items="limitOptions"
          label="Rows per page"
          dense
          solo
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { get, sync } from 'vuex-pathify'

  export default {
    name: 'TableSchedule',
    data: () => ({
      tempInterval: null,
    }),
    computed: {
      ...get('schedule', [
        'dataItems',
        'totalItem',
      ]),
      ...get('layout', [
        'limitOptions',
      ]),
      ...sync('schedule', [
        'params',
        'isShowDialogScheduleForm',
        'dataForm',
      ]),
      targetScheduleId() {
        return this.$route.query?.id || ''
      },
    },
    watch: {
      'params.offset': {
        handler (newVal, oldVal) {
          // when change offset then call api
          this.$store.dispatch('schedule/fetchDataScheduleList', this.getTokenLogin())
        },
        deep: true,
      },
      'params.limit': {
        handler (newVal, oldVal) {
          // when change limit then call api and offset start = 1
          if (this.params.offset !== 1) {
            this.params.offset = 1
          } else {
            this.$store.dispatch('schedule/fetchDataScheduleList', this.getTokenLogin())
          }
        },
        deep: true,
      },
    },
    async created () {
      if (this.params.offset !== 1) {
        this.params.offset = 1
      } else {
        await this.$store.dispatch('schedule/fetchDataScheduleList', this.getTokenLogin())
      }
      this.tempInterval = setInterval(() => {
        this.$store.dispatch('schedule/fetchDataScheduleList', this.getTokenLogin())
      }, 1000 * 10)
    },
    beforeDestroy() {
      clearInterval(this.tempInterval)
    },
    methods: {
      async clickDownloadFile(id) {
        await this.$store.dispatch('schedule/downloadFile', {
          tokenLogin: this.getTokenLogin(),
          id,
        })
      },
    },
  }
</script>
